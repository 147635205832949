import React from 'react';
import { Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';

import CloudBgBottom from '../assets/images/cloud-bg-bottom.svg';
import FolderIcon from '../assets/icons/folder.svg';

const ModulesSection = ({ data }) => (
  data.section.map((module, idx) => {

    if (module.slug != 'hidden') {
      return (
        <div
          className={`content-section section module ${idx === 0 && 'bg-gradient'} ${data.section.length - 1 === idx && 'bg-gradient-reversed'
            }`}
          key={module.id}
        >
          <div className="container">
            <div className="w-layout-grid content-grid module-content">

              {module?.image?.url && (
                <div className="image-wrapper">
                  <img src={module?.image?.url} alt="Module" className="content-image" />
                </div>
              )}

              <div className="content-block">
                <h2 className="content-h2">
                  <Link to={`/modules/${module.slug}`}>{module.title}</Link>
                </h2>

                <ReactMarkdown children={module.shortDescription} />

                <div className="w-layout-grid grid">
                  {module.subCategory &&
                    module.subCategory.map((category) => (
                      <div className="feature-wrap" key={category.slug}>
                        <img src={FolderIcon} alt="Folder" className="feature-icon" />
                        <div className="feature-content-wrap">
                          <Link to={`/modules/${module.slug}/${category.slug}`} className="link">
                            {category.name}
                          </Link>
                        </div>
                      </div>
                    ))}
                </div>

              </div>
            </div>
          </div>

          {data.section.length - 1 === idx && (
            <div className="div-block-2 bottom">
              <img src={CloudBgBottom} height="60" alt="Clouds Background" className="image" />
            </div>
          )}
        </div>
      )
    }
  }
  )

);

export { ModulesSection };
