import React, { useRef, useState } from 'react';
import { buildClient } from '@datocms/cma-client-browser';
import { useSelector } from 'react-redux';
import { useSiteSearch } from 'react-datocms';

const client = buildClient({ apiToken: process.env.GATSBY_DATO_API_TOKEN });

const moduleMapping = {
  IB: '7420490',
  'AP Calculus': '7420144',
  'AP Statistic': '7420145',
  KS2: '7420141',
  '11+': '7420141',
  UKMT: '7420141',
};

const cateogryMapping = {
  'A Level': '12185416',
  GCSE: '16224046',
  iGCSE: '16224047',
  Edexcel: '12100640',
  'Edexcel IAL': '12101025',
  AQA: '12100540',
  Cambridge: '18082164',
  OCR: '12100545',
  'OCR MEI': '12100546',
};

export default function Search({ onEscape }) {
  const user = useSelector((state) => state.user.data);

  const isSubscribed = user?.subscribed ?? false;

  const [query, setQuery] = useState('');
  const inputRef = useRef(null);
  const [documents, setDocuments] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState('');
  const [selectedExamBoard, setSelectedExamBoard] = useState('');
  const [documentsLoading, setDocumentsLoading] = useState(false);
  const { state, error, data } = useSiteSearch({
    client,
    initialState: { locale: 'en' },
    buildTriggerId: '31949',
    resultsPerPage: 6,
    highlightMatch: (text, key, context) => <strong key={key}>{text}</strong>,
  });

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  function escapeFunction(e) {
    if (e.key === 'Escape') {
      onEscape();
    }
  }

  React.useEffect(() => {
    document.addEventListener('keydown', (e) => escapeFunction(e), false);

    return document.removeEventListener('keydown', (e) => escapeFunction(e), false);
  }, [escapeFunction]);

  const slugify = (text) =>
    text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w\-]+/g, '') // Remove all non-word chars
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text

  const search = async (level, examBoard) => {
    setDocumentsLoading(true);
    state.setQuery(`${level || ''} ${query} ${examBoard || ''}`);
    if (query.length === 0 && !level && !examBoard) {
      setDocuments([]);
    } else {
      const selectedModules = [moduleMapping[level], moduleMapping[examBoard]].filter((v) => v);
      const selectedCategories = [cateogryMapping[level], moduleMapping[examBoard]].filter(
        (v) => v,
      );

      const documentParams = {
        filter: {
          type: '445121',
          query: `${query}`,
          ...(selectedModules.length && {
            module: {
              in: selectedModules,
            },
          }),
          ...(selectedCategories.length && {
            document_category: {
              in: selectedCategories,
            },
          }),
          locale: 'en',
        },
      };

      const records = (await client.items.list(documentParams)).slice(0, 6);
      const documentCategories = [...new Set(records.map((doc) => doc.document_category).flat())];
      const documentCategoryRecordsQuery = client.items.list({
        filter: {
          ids: documentCategories.join(','),
        },
      });
      const modules = [...new Set(records.map((doc) => doc.module))];
      const moduleRecordQuery = client.items.list({
        filter: {
          ids: modules.join(','),
        },
      });
      const [documentCategoryRecords, moduleRecords] = await Promise.all([
        documentCategoryRecordsQuery,
        moduleRecordQuery,
      ]);
      setDocuments(
        records.map((record) => ({
          ...record,
          document_category: record.document_category.map((cat) =>
            documentCategoryRecords.find(({ id }) => id === cat),
          ),
          module: moduleRecords.find((mod) => mod.id === record.module),
        })),
      );
    }
    setDocumentsLoading(false);
  };

  const generate_link = (document) => {
    const fileUrl = document.file_url.includes('https://mymathscloud.ams3.digitaloceanspaces.com/')
      ? unescape(document.file_url.replace('https://mymathscloud.ams3.digitaloceanspaces.com/', ''))
      : document.file_url;
    //Free doc
    if (
      document.free_document ||
      document.free_sample ||
      (document.has_password && document.free_document)
    ) {
      return document?.is_subscription && isSubscribed
        ? document?.video_guide?.url
          ? `/embeded?id=${document.id}&videoGuide=${encodeURI(document?.video_guide?.url)}`
          : `/embeded?id=${document.id}`
        : document.is_subscription
        ? ''
        : `${process.env.GATSBY_BACKEND_URL}/api/download/modules/${fileUrl}?id=${document.id}`;
    } else {
      return document.is_subscription && isSubscribed
        ? document?.video_guide?.url
          ? `/embeded?id=${document.id}&videoGuide=${encodeURI(document?.video_guide?.url)}`
          : `/embeded?id=${document.id}`
        : `/modules/${document.module.slug}/${document.document_category
            .map((c) => c.slug)
            .join('/')}/${encodeURIComponent(slugify(document.name))}`;
    }
  };

  const showBody =
    (!data && !error) ||
    documentsLoading ||
    error ||
    !!data?.pageResults?.length ||
    !!documents.length;

  return (
    <div className="search-container">
      <div className="spotlight-search">
        <div className="header">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              search();
            }}
          >
            <input
              ref={inputRef}
              className="input"
              type="search"
              placeholder="Search for pages or documents"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
            <div className="dropdown-wrapper">
              <div className="form-item">
                <label htmlFor="level">Level</label>
                <select
                  key="level"
                  value={selectedLevel}
                  onChange={(e) => {
                    setSelectedLevel(e.target.value);
                    search(e.target.value, selectedExamBoard);
                  }}
                  className="dropdown"
                >
                  <option value="">All</option>
                  <option value="11+">11+</option>
                  <option value="UKMT">UKMT</option>
                  <option value="KS2">KS2</option>
                  <option value="KS3">KS3</option>
                  <option value="GCSE">GCSE</option>
                  <option value="iGCSE">iGCSE</option>
                  <option value="A Level">A Level</option>
                  <option value="IB">IB</option>
                  <option value="AP Calculus">AP Calculus</option>
                  <option value="AP Statistics">AP Statistics</option>
                  <option value="MAT">MAT</option>
                  <option value="STEP">STEP</option>
                  <option value="TMUA">TMUA</option>
                  <option value="BMAT">BMAT</option>
                  <option value="UCAT">UCAT</option>
                  <option value="SAT">SAT</option>
                  <option value="ACT">ACT</option>
                </select>
              </div>

              <div className="form-item">
                <label htmlFor="board">Exam board</label>
                <select
                  className="dropdown"
                  key="board"
                  value={selectedExamBoard}
                  onChange={(e) => {
                    setSelectedExamBoard(e.target.value);
                    search(selectedLevel, e.target.value);
                  }}
                >
                  <option value="">All</option>
                  <option value="Edexcel">Edexcel</option>
                  <option value="Edexcel IAL">Edexcel IAL</option>
                  <option value="AQA">AQA</option>
                  <option value="Cambridge">Cambridge</option>
                  <option value="OCR">OCR</option>
                  <option value="OCR MEI">OCR MEI</option>
                </select>
              </div>
            </div>
          </form>
        </div>
        {showBody && (
          <div className="body">
            {(!data && !error) || documentsLoading ? (
              <p>Loading...</p>
            ) : error ? (
              <p>Error! {error}</p>
            ) : !!data?.pageResults?.length || !!documents.length ? (
              <div className="result-container">
                <p className="mb-0 subtitle">Pages</p>
                {data?.pageResults.map((result) => (
                  <div className="result" key={result.id}>
                    <a className="link" href={result.url}>
                      {result.title}
                    </a>
                  </div>
                ))}
                <p className="mb-0 mt-1 subtitle">Documents</p>
                {documents.map((document, index) => (
                  <div className="result" key={index}>
                    <a className="link" href={generate_link(document)}>
                      {document.name}
                    </a>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}
