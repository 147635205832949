import React from 'react';
import Lottie from 'react-lottie';
import ReactMarkdown from 'react-markdown';
import { Link } from 'gatsby';

import Clouds from '../assets/images/clouds.svg';
import * as heroAnimation from '../assets/animations/hero-02.json';

const HeroSection = ({ data: { description, description2, title } }) => (
  <div id="Header" className="header">
    <div className="container-flex">
      <div className="hero-content">
        <h1 data-w-id="d5bc1306-866d-cd8a-d8b9-28a36f77012f" className="hero-h1">
          {title}
        </h1>
        {description && <ReactMarkdown className="hero-paragraph" children={description} />}
        {!description && description2 && (
          <div className="hero-paragraph" dangerouslySetInnerHTML={{ __html: description2 }} />
        )}
        <div data-w-id="27b0fa60-5623-70fc-34c2-15c71cd861b7" className="button-wrapper">
          <Link to="/modules" className="button w-button">
            Discover Modules
          </Link>
        </div>
      </div>
      <div className="hero-image-wrap">
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: heroAnimation.default,
          }}
          role="presentation"
        />
      </div>
    </div>
    <img src={Clouds} alt="Clouds" className="pattern-left" />
  </div>
);

export { HeroSection };
