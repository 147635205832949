import React from 'react';
import ReactMarkdown from 'react-markdown';
import VideoPlayer from '../components/VideoPlayer';

export function MotivationSection(content) {
  const download_file = (url, name) => fetch(url)
    .then(res => res.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${name}.pdf`;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
    });

  return (
    content.videos.map((video, index) => {
      const videoUrl = video.images.find(image => image?.video?.streamingUrl).video?.streamingUrl;
      const pdfUrl = video.images.find(({ url }) => url.endsWith('.pdf'))?.url;

      return (
        <div className="motivation-item" key={index}>
          <p className="1.5 bold">{video.name}</p>
          {video.description && <ReactMarkdown className="paragraph" children={video.description} />}
          {!video.description && video.description2 && (
            <div dangerouslySetInnerHTML={{ __html: video.description2 }} />
          )}
          {pdfUrl && (
            <button className="button m-0 mb-0.25" onClick={() => download_file(pdfUrl, video.name)}>
              Download the video as a PDF here
            </button>
          )}
          <VideoPlayer src={video.youtubeVideo || videoUrl} />
        </div>
      )
    })
  )
}