import React from 'react';

import CloseIcon from './../assets/icons/close.svg';

import { newsletterSignUp } from './../utils/mmc-api/api';

const isBrowser = typeof window !== undefined;

const LS_NEWSLETTER_CLOSED = `newsletterClose-${new Date().toDateString()}`;

function Chevron({ size = '1rem' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      style={{
        width: size,
        height: size,
      }}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
    </svg>
  );
}

export default function () {
  const [closed, setClosed] = React.useState(false);
  const [closing, setClosing] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [error, setError] = React.useState('');

  const closeCard = () => {
    setClosing(true);
    setTimeout(() => {
      setClosed(true);
    }, 300);
    localStorage.setItem(LS_NEWSLETTER_CLOSED, 'true');
  };

  const handleSignUp = async () => {
    setError('');
    const response = await newsletterSignUp({ email });
    if (!response) {
      setError('Error signing up for newsletter');
      return;
    }
    localStorage.setItem('newsletter-sign-up', 'yes');
    closeCard();
  };

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      for (const key in localStorage) {
        if (key.substring(0, 15) == 'newsletterClose' && key !== LS_NEWSLETTER_CLOSED) {
          localStorage.removeItem(key);
        }
      }
    }
    const is_signed_up =
      typeof window !== 'undefined' &&
      (localStorage.getItem('newsletter-sign-up') || localStorage.getItem(LS_NEWSLETTER_CLOSED));
    if (is_signed_up) {
      setClosed(true);
      setClosing(true);
    }
  }, []);

  return (
    <div className="newsletter-sign-up-container">
      {!closed ? (
        <div className={`newsletter-container force-open ${closing ? 'closed' : 'open'}`}>
          <div className="close-icon" onClick={() => closeCard(true)}>
            <img src={CloseIcon} alt="Close icon" />
          </div>
          <div className="chevron">
            <Chevron />
          </div>
          <p>Newsletter sign up!</p>
          <p className="description">Enter your email to sign up to our newsletter for updates!</p>
          {error && <p className="description">{error}</p>}
          <input className="input" value={email} onChange={(e) => setEmail(e.target.value)} />
          <button className="button ml-0 mt-0.5" onClick={handleSignUp}>
            Sign up!
          </button>
        </div>
      ) : null}
    </div>
  );
}
