import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { navigate } from 'gatsby';
import PlusIcon from '../assets/icons/plus.svg';
import DocumentIcon from '../assets/icons/pdf2.svg';
import LockIcon from '../assets/icons/lock.svg';
import LockSubIcon from '../assets/icons/sub-lock.svg';
import UnlockSubIcon from '../assets/icons/open-sub-lock.svg';
import UnlockIcon from '../assets/icons/open-lock.svg'
import { addToCart } from '../store/cart';

const CollapsibleTopicBlock = ({ data: { documents, title }, miniCart, ownedProducts }) => {
  documents.sort((a, b) => a.node.name.localeCompare(b.node.name));
  
  const user = useSelector((state) => state.user.data);
  const isSubscribed = user?.subscribed ?? false;

  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => setIsOpen(!isOpen);
  const dispatch = useDispatch();

  function mainButtonClick(id) {
    dispatch(addToCart(id, user));
  }

  const slugify = (text) =>
    text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w\-]+/g, '') // Remove all non-word chars
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text

  return (
    <div className="faq-question-wrap">
      <button className="faq-question-bar w-inline-block" onClick={handleToggle}>
        <div className="faq-wrap">
          <img src={PlusIcon} alt="Plus" className={`faq-icon ${isOpen ? 'is-open' : ''}`} />
        </div>
        <div className="question-title">
          {title}
          {documents.length > 0 ? (
            <div className="topic-count">
              {' '}
              {documents.length} document{documents.length > 1 ? 's' : ''}
            </div>
          ) : (
            ''
          )}
        </div>
      </button>
      {isOpen &&
        documents.map((document) => {
            let documentState = "Buy"
            if (miniCart.has(document.node.originalId)) {
                documentState = 'in cart';
            }
            
            if (ownedProducts.has(document.node.originalId)) {
                documentState = 'Purchased';
            }

            if (document.node.isSubscription && isSubscribed && !ownedProducts.has(document.node.originalId)) documentState = 'Open';

            const freeDocument = document.node.freeSample || document.node.freeDocument;
            const cleanFileUrl = document.node.fileUrl?.includes(
                'https://mymathscloud.ams3.digitaloceanspaces.com/',
            )
                ? unescape(
                    document.node.fileUrl.replace(
                        'https://mymathscloud.ams3.digitaloceanspaces.com/',
                        '',
                    ),
                )
                : document.node.fileUrl;

            const handleDocumentClick = () => {
                switch (documentState) {
                    case 'Buy':
                        mainButtonClick(document.node.originalId);
                        break;
                    case 'Purchased':
                        navigate('/profile');
                        break;
                    case 'Open':
                        //navigate to open page
                        navigate(`/embeded?id=${document.node.originalId}`);
                        break;
                    default:
                        navigate('/cart');
                        break;
                }
            }

          return (
            <div className="faq-content mt-1" key={document.node.originalId}>
              <div className="feature-wrap">
                <img src={DocumentIcon} alt="Folder" className="feature-icon" />
                <div className="feature-content-wrap collapsible-topic-block-item">
                  <a
                    href={
                        (document.node.isSubscription && isSubscribed)
                            ? `/embeded?id=${document.node.originalId}`
                            : freeDocument
                                ? `http://mymathscloud.com/api/download/topics/${document.node.name}?id=${document.node.originalId}`
                                : `${process.env.GATSBY_BACKEND_URL}/modules/${
                                        document.node.module.slug || ''
                                    }/${document.node.documentCategory[0]?.slug || ''}/${encodeURIComponent(
                                        slugify(document.node.name),
                                    )}`
                    }
                    className="link"
                    key={document.node.originalId}
                    style={{ fontSize: '0.9rem' }}
                    rel="noreferrer"
                  >
                    {document.node.name}
                  </a>
                </div>
                <div className="category-option-info">
                  {document.node.freeSample && <div className="badge free-sample">FREE SAMPLE</div>}
                  {!document.node.freeSample &&
                    !document.node.freeDocument &&
                    document.node.hasPassword && (
                      <div className="srow y-centered no-wrap">
                        <div>
                            <div className={`badge premium ${(document.node.isSubscription && isSubscribed) ? 'strike-through' : ''}`}>
                                £{document.node.price || '20.00'}
                            </div>
                            <button
                                className={`button-b badge free-sample ${(document.node.isSubscription && isSubscribed) ? 'green-button' : ''}`}
                                onClick={handleDocumentClick}
                            >
                                {documentState}
                            </button>
                        </div>
                        {
                            documentState === 'Open'
                                ? (<div className="srow centered">
                                    <img src={UnlockSubIcon} className="lock-icon" alt="Unlock sub icon" onClick={handleDocumentClick}/>
                                </div>)
                                : (
                                    document?.node?.isSubscription
                                        ? !isSubscribed 
                                            ? (<img src={LockSubIcon} className="lock-icon" alt="Unlock sub icon" onClick={() => navigate('/subscriptions')}/>)
                                            : (<img src={UnlockSubIcon} className="lock-icon" alt="Unlock sub icon" onClick={handleDocumentClick}/>)
                                        : documentState !== 'Purchased'
                                            ? (<img src={LockIcon} className="lock-icon" alt="Unlock sub icon" onClick={handleDocumentClick}/>)
                                            : (<img src={UnlockIcon} className="lock-icon" alt="Unlock sub icon" onClick={handleDocumentClick}/>)
                                    
                                )
                        }
                     </div>
                    )}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export { CollapsibleTopicBlock };
