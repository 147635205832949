import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';

import PlusIcon from '../assets/icons/plus.svg';

const CollapsibleBlock = ({ data: { description, title } }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => setIsOpen(!isOpen);
  return (
    <div className="faq-question-wrap">
      <button className="faq-question-bar w-inline-block" onClick={handleToggle}>
        <div className="faq-wrap">
          <img src={PlusIcon} alt="Plus" className={`faq-icon ${isOpen ? 'is-open' : ''}`} />
        </div>
        <div className="question-title">{title}</div>
      </button>
      {isOpen && (
        <div className="faq-content">
          <ReactMarkdown className="faq-paragraph" children={description} />
        </div>
      )}
    </div>
  );
};

export { CollapsibleBlock };
